import React from "react";

import MonoNav from "../components/MonoNav";
import Footer from "../components/Footer";

import './projects.css'


// images
import Vulnexec from '../images/vulnexec.png'
import Lgsm from '../images/lgsm_autowipe.png'
import open_usage from '../images/open_usage.png'
import license_server from '../images/license_server.png'
import placeholder from '../images/placeholder.png'
function Projects() {

    return (
        <div className='App'>
            <div className="App-header">
                <MonoNav />

            </div>

            <div className="body">
                <div class="image-container">
                    <div className="image-section">
                        <img src={Vulnexec} alt="VulnExec" />
                        <div className="content">
                            <h2>VulnExec</h2>
                            <p>VulnExec is an automated penetration testing tool which was made in colaboration with Hx. Originally created as a university project, it quickly became something that we could use in our everyday life. VulnExec Accepts an IP address and an Attack Type (Loud/Quiet). From there it will automatically scan the host to find any exposed services that may be running on the system. After that it will compare the service versions to a CVE Database to find any exploits. If an exploit is found, VulnExec will automatically find the right method and approach to gain access to the machine. All Done without requiring any human interaction</p>
                            <a href="https://vulnexec.com" target="_blank" rel="noopener noreferrer">View On Github</a>
                        </div>
                    </div>
                    <hr className="divider"/>
                    <div className="image-section">
                        <img src={Lgsm} alt="VulnExec" />
                        <div className="content">
                            <h2>LGSM Auto Wiper</h2>
                            <p>LGSM Auto Wiper is a script created for Linux Game Server Manager for the game rust where its required to have server-wide wipes every week. And since its rather annoying to have to be around for the wipe its always good to have a way of automating it. Therefore the script exists. It will automatically stop the server, Remove any files that aren't needed. Allows for custom maps to be set or completely random ones. Then reboots the server with an updated name that works off the current date to ensure your server is seen when players are looking for a server to play. All in a neat little Javascript file.</p>
                            <a href="https://www.sourcexchange.net/products/lgsm-rust-auto-server-wiper" target="_blank" rel="noopener noreferrer">View On Sourcexchange</a>
                        </div>
                    </div>
                    <hr className="divider" />
                    <div className="image-section">
                        <img src={open_usage} alt="" />
                        <div className="content">
                            <h2>Open-Usage</h2>
                            <p>Open Metrics is an open source, API Driven system metrics retrieval service. Open metrics was designed to make it easy to monitor your infrastucture without having to use 3rd party services such as telegraf or Prometheus. With Open metrics you are given a JSON object that has every useful system metric so you can keep track. All accessible via a simple get request. Open metrics can either be opened to the public view with no authentication or With Either IP Verification / Token based authentication. Highly customizable. Fully Open source. Coded to let anyone modify it in any way they like.</p>
                            <a href="https://github.com/jake0001-dev/open-usage" target="_blank" rel="noopener noreferrer">View On Github</a>
                        </div>
                    </div>
                    <hr className="divider" />
                    <div className="image-section">
                        <img src={license_server} alt="" />
                        <div className="content">
                            <h2>Open Source License Server</h2>
                            <p>OSLS (Open Source License Server) is a free, Open Source, Licensing Server of which i began a few months ago. The reason behind me creating this was due to the amount of people who provide services / Products that are not DRM protected and allow for them to be pirated etc. So i created OSLS. The main benefit of OSLS is that anyone can use it to manage product keys / licenses in house with ease. With functional license validity checks and secure data management, it makes managing your user licenses easy without having to spend a penny on 3rd party software from venders who overcharge too much for a service that is sub-par.</p>
                            <a href="https://github.com/Cyphersoftware-uk/License_server" target="_blank" rel="noopener noreferrer">View On Github</a>
                        </div>
                    </div>
                    

                </div>

                <div className="App-footer">
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Projects;