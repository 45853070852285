import React, { useState, useEffect, useCallback } from 'react';
import './typewriter.css'; // Include the necessary CSS file or add styling here

const Typewriter = ({ initialString }) => {
    const [typedText, setTypedText] = useState(":");
    const [stringIndex, setStringIndex] = useState(0);

    const typeAndDelete = useCallback(() => {
        const string = initialString;

        // Type the text
        for (let i = 0; i <= string.length; i++) {
            setTimeout(() => {
                setTypedText(`:${string.substring(0, i)}`);
            }, i * 100);
        }

        // Wait for 2 seconds
        setTimeout(() => {
            // Delete the text
            for (let i = string.length; i >= 0; i--) {
                setTimeout(() => {
                    setTypedText(`:${string.substring(0, i)}`);
                }, (string.length - i) * 100);
            }

            // Move to the next string after deleting
            setTimeout(() => {
                // Uncomment the line below if you want to set the stringIndex to 0 after reaching the end
                // setStringIndex((prevIndex) => (prevIndex + 1) % strings.length);
            }, string.length * 100 + 1000); // 1000 milliseconds = 1 second
        }, string.length * 100 + 2000); // 2000 milliseconds = 2 seconds
    }, [initialString]);

    useEffect(() => {
        setInterval(() => {
            typeAndDelete();
        }, 10000)
        typeAndDelete()
    }, [typeAndDelete]);

    return (
        <div className="typewriter">
            <h5 className='sub-text'><span className="dollar-sign">$</span>{typedText}<span className="cursor"></span></h5>
        </div>
    );
};

export default Typewriter;
