import MonoNav from "../components/MonoNav";
import Footer from "../components/Footer";

import './usefullinks.css'
// images
import javascript from "../images/javascript.svg";

function Usefullinks() {
  return (
    <div className="App">
        <header className="App-header">
            <MonoNav />
        </header>
        <div className="Usefullinks-title"> <h1>Useful Links</h1> </div>
        <div className="Usefullinks-body">
            <ul>
                  <li><a href="npmjs.com" target="_blank" rel="noopener noreferrer">NPM - Node Libraries</a></li>
                  <li><a href="https://stackoverflow.com/" target="_blank" rel="noopener noreferrer" >Stack Overflow - Get Stuck? Find a solution</a></li>
                  <li><a href="https://check-host.net/" target="_blank" rel="noopener noreferrer">Check-Host - Find network issues and where the site isn't getting routed</a></li>
                  <li><a href="https://codebeautify.org/jsonviewer?utm_content=cmp-true" target="_black" rel="noopener noreferrer">JSON Beautifying - Help Your Eyes and Brain</a></li>
                  <li><a href="https://www.w3schools.com/" target="_blank" rel="noopener noreferrer">W3 Schools - Learn to code</a></li>
                  <li><a href="https://www.youtube.com/channel/UC9x0AN7BWHpCDHSm9NiJFJQ" target="_blank" rel="noopener noreferrer">Youtube - Network Chuck</a> </li>
                  <li><a href="https://www.youtube.com/@_JohnHammond" target="_blank" rel="noopener noreferrer">Youtube - Johh Hammond</a> </li>
                  <li><a href="https://www.youtube.com/@DavesGarage" target="_blank" rel="noopener noreferrer">Youtube - DavesGarage (Because he is a Gamer for Coding Task Manager)</a> </li>
                  <li><a href="https://icons8.com/" target="_blank" rel="noopener noreferrer">Icons8 - Every Icon for Nothing!</a> </li>
                  <li><a href="https://github.com/jake0001-dev" target="_blank" rel="noopener noreferrer">Jake0001-dev Github</a> </li>
                  
            </ul> 
        </div>
        <div className="App-footer">
            <Footer />
        </div>
    </div>
  )
}


export default Usefullinks;
