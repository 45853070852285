import React from "react";
import "./MonoNav.css";
const MonoNav = () => {
    return (
        <div className="MonoNav">
            <div className="MonoNav-item">
                <a href="/" >HOME</a>
            </div>
            <div className="MonoNav-item">
                <a href="/about" >ABOUT</a>
            </div>
            <div className="MonoNav-item">
                <a href="/projects" >PROJECTS</a>
            </div>
            <div className="MonoNav-item">
                <a href="/languages" >Languages</a>
            </div>
            <div className="MonoNav-item">
                <a href="/contact">Contact</a>
            </div>
            <div className="MonoNav-item">
                <a href="/lookingglass">Looking Glass</a>
                </div>
        </div>
    );
}

export default MonoNav;