import Terminal from 'react-console-emulator'
import React from 'react'

import './console.css'


const Console = () => {

   const commands = {
        
        lua : {
            description: 'Show My Lua Experience',
            usage: 'lua',
            fn: () => {
                return 'Back in 2015 i learn\'t lua for some additions to cheats. Mostly for CS:GO at the time and alot of the games had LUA script support. I would create little nice to haves like a chart that showed you how many shot you hit and how many you missed using a pie-chart. Or you could do it via a Line Graph. I also made custom scripts for importing different weapon sounds, Such as the ray gun from Blackops 2 for the USP-S'
            }
        },
        javascript : {
            description: 'Show My JavaScript Experience',
            usage: 'javascript',
            fn: () => {
                return 'I have been using JavaScript for around 4 years now. I have used it for many things such as Discord Bots, Websites, and even some games. I have used many frameworks such as React, NextJS, Express, and more. I have also used many libraries such as DiscordJS, Axios, and more.'
            }
        },

        python : {
            description: 'Show My Python Experience',
            usage: 'python',
            fn: () => {
                
                return 'I have been using python here and there for a few years now. I really don\'t like it. Much prefer JavaScript overall. I only use it when i have to.'
            }
        },
        react : {
            description: 'Show My React Experience',
            usage: 'react',
            fn: () => {
                return 'I\'ve only been using react for a few months now. However i quite like it, This entire site was coded in react and i really like it. I have also used NextJS which is a framework for react. I have also used react-native for mobile development.'
            }
        },
        css :{
            description: 'Show My CSS Experience',
            usage: 'css',
            fn: () => {
                return 'I would say im not a web-dev but i can do some CSS. I have used it for a few years now and i know my way around it. I have used many frameworks such as Bootstrap, Tailwind, and more. I have also used many libraries such as Animate.css, and more.'
            }
        },
        html : {
            description: 'Show My HTML Experience',
            usage: 'html',
            fn: () => {
                return 'I would say im not a web-dev but i can do some HTML. I have used it for a few years now and i know my way around it. I have used many frameworks such as Bootstrap, Tailwind, and more. I have also used many libraries such as Animate.css, and more.'
            }
        },
        bash : {
            description: 'Show My Bash Experience',
            usage: 'bash',
            fn: () => {
                return 'I have been using bash for a few years now. My everyday go-to would be debian 12 which i have been running for a while, Previously to that i was using Ubuntu 20.04 LTS. I have also used many other distros such as Arch, Kali, and Parrot. I have also used many shells such as ZSH, Fish, and more.'
            }
        }
    
    };
    return (
        <div className="console">
            <Terminal
                commands={commands}
                welcomeMessage='Type Help to get started.'
                ignoreCommandCase = {true}
                promptLabel={'root@jake0001:$'}
            />
        </div>
    )
}

export default Console