import './notrack.css';
import MonoNav from '../components/MonoNav.js';
import Footer from '../components/Footer.js';

function notrack() {
    return (
        <div className='notrack'>
            <div className="App-header">
                <MonoNav />
            </div>
            <div className="body">
                
                <code>
                    <h4>How I handle your data</h4>
                    <h5>I believe anonymity and proactively preventing trackers is how the internet should be. Here are some ways in which i try and keep you as anonymous as possible :</h5>
                    <ul>
                        <li>I do not log any form of identifying data. Such as IP Address, User Agents</li>
                        <br></br>
                        <li>NGINX Access Logs are automatically cleared when a user sends a request therefore its never stored</li>
                        <br></br>
                        <li>No Ads/Cookies are used on this site so no need to worry about 3rd parties not keeping their end of the deal</li>
                        <br></br>
                        <li>No Form of analytics are used or monitored other then ones required for the site (Serve / Error Handling)</li>
                        <br></br>
                        <li>Any data you send to the site is encrypted using TLS 1.3</li>
                        <br></br>
                        <li>On Contact Requests it will never log any other information then the one you have submitted</li>
                        <br></br>
                        <li>Your online experience is yours alone. I don't create detailed user profiles based on your activities, and our content is not personalized through invasive algorithms.</li>
                        <br></br>
                        <li>Enjoy a tracking-free experience. I do not use web beacons or tracking pixels, ensuring that your online activities remain private.</li>
                        <br></br>
                        <li>Your communication with our site is secure. I use end-to-end encryption to safeguard your data, ensuring that it remains confidential between your device and our servers.</li>
                        <br></br>
                        <li>Your privacy matters. Feel free to connect to our site using virtual private networks (VPNs) or proxies, protecting your identity and ensuring a secure connection.</li>
                        <br></br>
                        <li>Trust in our commitment to your security. I implement strong security measures to protect your data, ensuring it remains confidential even in the unlikely event of a breach.</li>
                    </ul>
                </code>
            </div>
            <div className="App-footer">
                <Footer />
            </div>
        </div>
    );
}

export default notrack;