
import './home.css';
import { useEffect, useCallback } from 'react';
import { BrowserRouter } from 'react-router-dom';



// components
import GlitchedText from '../components/GlitchedText';
import MonoNav from '../components/MonoNav';
import Footer from '../components/Footer';
import Typewriter from '../components/typewriter';

function home() {

    return (

            <div className="App">
                <header className="App-header">
                    <MonoNav />
                </header>
                <div className="main-text">
                    <GlitchedText />
                    <Typewriter  initialString={"Software Development Without Limits"}/>
                </div>
                <div className="App-footer">
                    <Footer />
                </div>
            </div>

    );
}

export default home;