import React from "react";
import MonoNav from "../components/MonoNav";
import Footer from "../components/Footer";
import './about.css'


import anonymous from "../images/icons8-anonymous-64.png";

function About() {
    return (
        <div className='App'>
            <div className="App-header">
                <MonoNav />
            </div>
            <div className="section-l">
                <h2>Who is Jake0001?</h2>
                <p>I am Jake0001, commonly referred to as Jake or Jake-Dev. As a seasoned software developer based in the United Kingdom, my expertise lies in the realms of cyber security and backend applications. Proficient in a diverse array of programming languages, details of which can be found <a href="/languages" target="_blank" rel="noopener noreferrer">[here]</a>, I am dedicated to leveraging my skills to develop innovative software solutions that positively impact and assist individuals. An advocate for open source software, I actively contribute to projects to foster collaborative development within the software community.</p>
            </div>
            <div className="section-l">
                <h2 >What is Jake0001?</h2>
                <p>I, Jake0001, embody the spirit of a dynamic startup company rooted in the vibrant tech scene of the United Kingdom. As the founder and lead software developer of this venture, I bring a wealth of experience to the table. This company is not merely about writing code; it's my hub of innovation, a space where I am constantly pushing the boundaries of what's possible in software development. Specializing in Software as a Service (SaaS), I take pride in delivering bespoke solutions that specifically address the unique challenges faced by our clients. This startup is more than a collection of projects; it's my journey of collaboration, problem-solving, and continual improvement. Beyond the technical realm, I am deeply committed to fostering a culture of creativity and excellence within my startup. I navigate the ever-changing landscape of technology with enthusiasm, making strategic moves to stay ahead in the competitive startup ecosystem. Jake0001 isn't just a name—it's a symbol of my startup's commitment to driving technological advancement, one innovative solution at a time.</p>
            </div>
            
            <div className="section-l">
                <h2>Why is Jake0001?</h2>
                <p>Jake0001 exists as a driving force in the realm of software development to fulfill a distinct purpose. At the core of Jake0001's existence lies the passion for pushing the boundaries of technology, innovating, and crafting solutions that make a meaningful impact. The purpose extends beyond lines of code; it encompasses a commitment to excellence, a dedication to delivering valuable software solutions, and a desire to contribute to the broader advancement of technology. Jake0001 exists to create, inspire, and lead, shaping the landscape of software development with a vision that goes beyond the ordinary, aiming to leave a lasting imprint on the world of technology.</p>
            </div>
            <div className="App-footer">
                <Footer />
            </div>
        </div>

    )
}

export default About;