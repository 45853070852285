import React from "react";

import "./Footer.css";

const Footer = () => {
    return (
        <div className="Footer">
            <div className="Footer-item">
                <a href="/notrack" target="_blank" rel="noopener noreferrer">No Log Policy</a>
            </div>
            <div className="Footer-item">
                <a href="/Useful" target="_blank" rel="noopener noreferrer">Useful Links</a>
            </div>
        </div>


    );
}

export default Footer;