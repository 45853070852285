import React, { useState } from 'react';
import MonoNav from '../components/MonoNav'; // Adjust the path as needed
import Footer from '../components/Footer'; // Adjust the path as needed
import './lookingglass.css'; // Import the CSS file for styling

const ApiExampleComponent = () => {
    const [ip, setIp] = useState('');
    const [tracertData, setTracertData] = useState({
        hops: [{ ip: '', time: '' }],
    });
    const [whoisData, setWhoisData] = useState('No data');
    const [pingData, setPingData] = useState({ ip: '', avg: '', min: '', max: '' });
    const apiUrl = 'https://backend.jake.systems/api/';



    const fetchData = async () => {
        try {
            // Fetch tracert data
            const tracertResponse = await fetch(`${apiUrl}tracert?ip=${ip}&token=6d773fc8-0644-406a-96fe-4ad71d88dd0b`);
            const tracertJson = await tracertResponse.json();
            setTracertData(tracertJson);

            // Fetch whois data
            const whoisResponse = await fetch(`${apiUrl}whois?ip=${ip}&token=6d773fc8-0644-406a-96fe-4ad71d88dd0b`);
            const whoisJson = await whoisResponse.json();
            setWhoisData(whoisJson);

            // Fetch ping data
            const pingResponse = await fetch(`${apiUrl}ping?ip=${ip}&token=6d773fc8-0644-406a-96fe-4ad71d88dd0b`);
            const pingJson = await pingResponse.json();
            setPingData(pingJson);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        if (!ip) {
            alert('Please enter an IP address');
            return
        }

        // check if IP is valid
        const ipRegex = new RegExp(
            '^([0-9]{1,3}\.){3}[0-9]{1,3}$'
        );

        if (!ipRegex.test(ip)) {
            alert('Please enter a valid IP address');
            return
        }

        fetchData();
        
        document.querySelector('.submitted').hidden = false;

        setTimeout(() => {
            document.querySelector('.submitted').hidden = true;
        }, 30000);
    };

    return (
        <div className="monochrome-container">
            <div className="App-header">
                <MonoNav /> {/* Assuming you have a MonoNav component */}
            </div>

            <div className="content-container">
                <form onSubmit={handleSubmit}>
                    <label>
                        Enter IP Address:
                        <input type="text" value={ip} onChange={(e) => setIp(e.target.value)} />
                    </label>
                    <label className='submitted' hidden={true}> Submitted! - This will take a 30 seconds.</label>
                    <button className="submit-btn" type="submit">Submit</button>
                </form>
            </div>
            <div className='result-container'>
                <div className='traceroute'> <h2>Traceroute</h2>
                    {tracertData && tracertData.hops && (
                        <ul>
                            {tracertData.hops.map((hop, index) => (
                                <li key={index}>
                                    <strong>[Hop {index + 1}] - Host:</strong> {hop.ip}, <strong>Time:</strong> {hop.time} ms
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className='whois'> <h2>Whois Records</h2>
                    {whoisData && (
                        <div>
                            <p>
                                <strong>Country:</strong> {whoisData.country},<br />
                                <strong>ASN:</strong> {whoisData.asn},{' '}<br />
                                <strong>ASN Name:</strong> {whoisData.asn_name},<br />
                                <strong>ISP:</strong> {whoisData.isp}
                            </p>
                        </div>
                    )}
                </div>

                <div className='ping'> <h2>Ping</h2>
                    {pingData && (
                        <div>
                            <p>
                                <strong>Average:</strong> {pingData.avg} ms, <strong>Minimum:</strong> {pingData.lowest} ms,{' '}
                                <strong>Maximum:</strong> {pingData.highest} ms
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className="App-footer">
                <Footer />
            </div>
        </div>
    );
};

export default ApiExampleComponent;
