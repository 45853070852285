import React from "react";

import MonoNav from "../components/MonoNav";
import Footer from "../components/Footer";
import './languages.css'
import Console from "../components/console";

function Languages() {
    return (
        <div className='App'>
            <div className="App-header">
                <MonoNav />
            </div>
            <div className="App-body">
                <Console />
            </div>
            <div className="App-footer">
                <Footer />
            </div>
        </div>
    );

}

export default Languages;

