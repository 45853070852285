import React from "react";
import "./GlitchedText.css";
import { useGlitch } from 'react-powerglitch'

const GlitchedText = () => {
    const glitch = useGlitch({
        glitchTimeSpan: 5000,
    });
    return (
        <div className="GlitchedText">
            <h1 {...glitch}>Jake0001-Dev</h1>
        </div>
    );
}

export default GlitchedText;