import React from "react";

import MonoNav from "../components/MonoNav";
import Footer from "../components/Footer";

import './socials.css'
import { redirect } from "react-router-dom";

// images
import github from "../images/github.svg";
import discord from "../images/discord.svg";
import twitter from "../images/twitter.svg";

function Socials() {
    var embed_data = {
        "embeds": [
            {
                "title": "New Contact!",
                "description": "A new contact has been made on the Jake.Systems!",
                "color": 16711680,
                "fields": [
                    {
                        "name": "Name",
                        "value": ""
                    },
                    {
                        "name": "Email",
                        "value": ""
                    },
                    {
                        "name": "Message",
                        "value": ""
                    }
                ]
            }
        ]
    }

    return (
        <div className='App'>
            <div className="App-header">
                <MonoNav />

            </div>

            <div className="contact-form">
                <h1>Contact Me</h1>
                <input type="text" placeholder="Alias" className="name-box" />
                <input type="text" placeholder="Email / Discord Username" className="email-box" />
                <textarea type="text" placeholder="Message" className="message-box" />
                <button className="send-btn" onClick={() => {

                    const name = document.querySelector('.name-box').value;
                    const email = document.querySelector('.email-box').value;
                    const message = document.querySelector('.message-box').value;

                    if (name === '' || email === '' || message === '') return alert('Please fill out all fields!')

                    embed_data.embeds[0].fields[0].value = name;
                    embed_data.embeds[0].fields[1].value = email;
                    embed_data.embeds[0].fields[2].value = message;



                    fetch('https://discord.com/api/webhooks/1161697741802578060/lrN4hIejPMxeCnFj82kKIc1f5LE00-Vs_IbXXhiCMmL_X022r-eQ-ooaSC7T-eGxgMzk', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(embed_data)
                    }).then(() => {
                        document.querySelector('.name-box').value = ''
                        document.querySelector('.email-box').value = ''
                        document.querySelector('.message-box').value = ''
                        document.querySelector('.send-btn').innerHTML = 'Sent!'
                    })


                }


                }>Send</button>
            </div>


            <div className="body">
                <div className="logo">
                    <img src={github} alt="logo" />
                    <a href="https://github.com/jake0001-dev" target="_blank" rel="noopener noreferrer"><h1>Github</h1></a>
                </div>
                <div className="logo">
                    <img src={discord} onClick={ () => redirect('https://discord.gg/J9bES5Sb3X') } alt="logo" />
                    <a href="https://discord.gg/J9bES5Sb3X" target="_blank" rel="noopener noreferrer"><h1>Discord</h1></a>
                </div>

                <div className="logo-twitter">
                    <img src={twitter} alt="logo" />
                    <a href="https://twitter.com/jake0001_dev" target="_blank" rel="noopener noreferrer"><h1>Twitter</h1></a>
                    </div>

            </div>


            <div className="App-footer">
                <Footer />
            </div>
        </div>

    );
}


export default Socials;